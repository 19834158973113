import axios from "axios";
import store from "@/store";

export default {
  pushCommentToId(objecttype, id, comment = "") {
    // eslint-disable-next-line no-param-reassign
    comment = comment.trim();
    return new Promise((resolve, reject) => {
      if (comment.length < 4 || typeof id === "undefined") {
        reject(new Error("Invalid id or comment too short"));
        return;
      }
      if (!(typeof objecttype === "string") && !(objecttype instanceof String) ) {
        reject(new Error("Invalid object type, must be String of at least 2", objecttype));
        return;
      }
      if (objecttype.length < 2 ) {
        reject(new Error("Comment too short", objecttype));
        return;
      }

      const url = `${store.state.API_ROOT}/api/${objecttype}/${id}/logs`;
      axios.post(url, { comment },  { withCredentials: true })
        .then((res) => {
          console.log("Q COM", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  getCommentsForId(objecttype, id) {
    console.log(`Getting comments for ${objecttype}/${id}`);

    // Debemos hacerlo con type y parentid para no complicar el Sentinel
    return new Promise((resolve, reject) => {
      if (!(typeof objecttype === "string") && !(objecttype instanceof String) ) {
        reject(new Error("Invalid object type, must be String of at least 2", objecttype));
        return;
      }
      if (objecttype.length < 2 ) {
        reject(new Error("Invalid object type, too short", objecttype));
        return;
      }
      const url = `${store.state.API_ROOT}/api/${objecttype}/${id}/logs`;
      axios.get(url, { /* params: query, */ withCredentials: true })
        .then((res) => {
          resolve(res.data);
        })
        .catch(err => {
          console.log(`ERROR getting comments for ${objecttype}/${id}`, err);
          reject(err);
        });
    });
  }
};
