<style scoped>
.comments {
  position: relative;
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
  max-height: 15rem;
  overflow-y: auto;
  /* display: flex;
  flex-direction: column; */
}
.comment-item {
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 0.5em;
  margin-top: 0.2em;
  background: #30363e79;
  /* color: #757e8a; */

  white-space: pre-wrap;
}
.comment-item.comment {
  display: none;
  background: #30363e79;
  color: #8d8d8d;
  position: absolute;
  right: 0;
  padding-left: 0.1em;
  /* padding-right: 0.1em; */
}
.reply::before  {
  /* padding-left: 1em; */
  content: '💬 ';
}
.email {
  display: block;
  position: relative;
  /* background: #30363e79; */
  color: #00000000;
  /* padding-left: 0.1em; */
  padding-right: 0.1em;
}
.comment-item:hover .email {
  display: block;
  color: #8d8d8d;
}

</style>
<template>
  <div>
    <slot></slot>
    <ul ref="comments" class="comments">
      <li v-for="it in comments.rows" :key="it._id" class="comment-item">
        <div>
          <a
            v-if="enablecomment"
            href="#"
            class="float-right"
            @click.prevent
          >
            <svg
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em"
              preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24" style="transform: rotate(360deg);"
            ><g fill="none"><path d="M19 5L5 19" stroke="#626262" stroke-width="2" stroke-linecap="round" class="il-md-length-25 il-md-duration-2 il-md-delay-2" /><path d="M5 5l14 14" stroke="#626262" stroke-width="2" stroke-linecap="round" class="il-md-length-25 il-md-duration-2 il-md-delay-0" /></g>
            </svg>
          </a>
        </div>
        <span class="comment">
          {{ it.comment }}
        </span>
        <br>
        <!-- <small class="text-right email">
          {{ it.email }}
        </small> -->
      </li>
    </ul>
    <p v-if="enablecomment" class="p-1">
      <textarea
        v-model="comment"
        tabindex="1"
        placeholder="Escriba el comentario (CTRL-ENTER para guardar)"
        rows="3"
        class="form-control"
        :class="{ 'border-danger': comment.trim().length>0 && comment.trim().length<5 }"
        @keyup.ctrl.enter="pushComment"
      ></textarea>
    </p>
    <button
      v-if="enablecomment"
      tabindex="3"
      class="btn btn-sm btn-info float-right"
      @click="pushComment"
    >
      <i class="fa fa-fw fa-save" aria-hidden="true"></i>
      Dejar comentario
    </button>
  </div>
</template>
<script>
import Comments from "./Comments.service";

export default {
  name: "CommentsPane",
  components: {
  },
  props: {
    objecttype: {
      type: String,
      required: true,
      default() { return ""; }
    },
    parentid: {
      type: String,
      required: true,
      default() { return ""; }
    },
    refresh: {
      type: Number,
      default: 0
    },
    enablecomment: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      comments: [],
      comment: ""
    };
  },
  watch: {
    refresh: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        this.reloadComments();
      }
    },
    parentid: {
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        this.reloadComments();
      }
    }
  },
  methods: {
    pushComment() {
      // console.log("CCCC", this.comment);
      Comments.pushCommentToId(this.objecttype, this.parentid, this.comment)
        .then(res => {
          this.comments = res;
          this.$emit("commented", this.comment);
          this.comment = "";
          this.reloadComments();
        })
        .catch(err => {
          const { response = {}, message = "" } = err;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string = message } = dataResponse;
          this.$emit("error", {
            status,
            message: error_string,
            objecttype: this.objecttype,
            parentid: this.parentid,
            comment: this.comment
          });
        });
    },
    reloadComments() {
      // console.log("Refreshing comments", this.objecttype, this.parentid);
      if (this.objecttype.length < 1 || this.parentid.length < 12) {
        // Mongo ObjectId is a 12-byte BSON type hexadecimal string
        return;
      }
      Comments.getCommentsForId(this.objecttype, this.parentid)
        .then(res => {
          this.comments = res;
        });
    }
  },
  mounted() {
  }
};
</script>
